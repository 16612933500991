<template>
  <header>
    <div class="user_menu">
      <button class="my_menu"><i></i>관리자님 안녕하세요.</button>
      <button class="logout" @click="logout()">로그아웃</button>
    </div>
  </header>
</template>

<script setup>
import {useStore} from 'vuex';
import { useRouter } from 'vue-router';
const router = useRouter()
const store = useStore();

function logout() {
  localStorage.clear();
  store.commit({type: 'setSessionToken', token: null})
  router.push({name : 'Login'})
}
</script>
