<template>
  <div id="navi" class="nav">
    <h1>
      <router-link to="/entry" v-if="store.getters.getRole === 'ROLE_MASTER'" class="logo">모든수산 관리자</router-link>
      <router-link to="/order" v-if="store.getters.getRole === 'ROLE_DELIVERY'" class="logo">모든수산 관리자</router-link>
    </h1>
    <ul>
      <li v-for="( menu, idx ) in naviItem" :class="{ active: activeMenu(menu) }">
        <template v-if="menu.children">
          <a class="depth1" :title="menu.name" :href="menu.url">{{ menu.name }}</a>
          <ul class="depth2">
            <li v-for="child in menu.children" :key="idx"
                :class="{ active: activeMenu(child) }">
              <a :href="child.url" :title="child.name" active-class="active" >{{ child.name }}</a>
            </li>
          </ul>
        </template>
        <template v-else>
          <a class="depth1" :href="menu.url" :title="menu.name">{{ menu.name }}</a>
        </template>
      </li>
    </ul>
  </div>
</template>

<script setup>

import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

const route = useRoute();
const store = useStore();


const master = [
  // {name: '대시보드', url: "/dashboard"},
  {
    name: '공급관리', url: "/entry",
    children: [
      {name: '입점신청', url: '/entry'},
      {name: '업체목록', url: '/company'},
      {name: '상품관리', url: '/product_list'},
      {name: '견적내역', url: '/estimate'},
      {name: '발주내역', url: '/placing_order'},
    ]
  },
  {
    name: '상품관리', url: "/product",
    children: [
      {name: '상품목록', url: '/product'},
      // {name: '상품등록', url: '/register'},
    ]
  },
  {
    name: '주문관리', url: "/order",
    children: [
      {name: '주문내역', url: '/order'},
      // {name: '반품내역', url: '/return'},
      {name: '문자발송', url: '/send_msg'},

    ]
  },
  {
    name: '회원관리', url: "/member",
    children: [
      {name: '회원목록', url: '/member'},
      {name: '그룹관리', url: '/group'},

    ]
  },
  {
    name: '사이트 설정', url: "/setting",
    children: [
      {name: '사이트 설정', url: '/setting'},
      {name: '공지사항', url: '/notice'},
      {name: '자주묻는질문', url: '/faq'},

    ]
  }
]

const delivery = [
  {
    name: '주문관리', url: "/order",
    children: [
      {name: '주문내역', url: '/order'},
    ]
  }
]

const naviItem = ref([]);

function activeMenu(menu) {
  let result = false;
  if (menu.children) {
    for (let item of menu.children) {
      if (item.url === route.path) {
        result = true;
      } else if(item.url === '/product' && (route.path == '/product_price' || route.path == '/product_modify' )) {
        result = true
      } else if(menu.url === '/member' && route.path == '/member_modify') {
        result = true
      }
    }
  } else if (menu.url === route.path) {
    result = true;
  } else if(menu.url === '/product' && route.path == '/product_price'|| route.path == '/product_modify' ) {
    result = true
  } else if(menu.url === '/member' && route.path == '/member_modify') {
    result = true
  }
  return result;
}

onMounted(() => {
  if (store.getters.getRole === 'ROLE_MASTER') {
    naviItem.value = master;
  } else {
    naviItem.value = delivery;
  }
})

</script>