import {createApp} from 'vue'
import App from './App.vue'
import createStore from './stores/store';
import router from './router/router'
import './assets/css/common.css'

import VueScrollingTable from "vue-scrolling-table"
import "/node_modules/vue-scrolling-table/dist/style.css"

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import store from "@/stores/store";
import filter from "@/plugin/filters"
import axios from "axios";
import moment from "moment";


const http = axios.create({
    baseURL: '', // url = base url + request url
    timeout: 60000,
});

http.interceptors.request.use((config) => {
    let token = store.getters.getSessionToken;
    if (!token) {
        const content = localStorage.getItem('am-admin-session');
        if (content) {
            token = content;
            if (token) {
                store.dispatch("setSessionToken", content).then();
            }
        }
    }
    if (token) {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})
let isTokenRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
    refreshSubscribers.map((callback) => callback(accessToken));
};

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};
http.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    //https://maruzzing.github.io/study/rnative/axios-interceptors%EB%A1%9C-%ED%86%A0%ED%81%B0-%EB%A6%AC%ED%94%84%EB%A0%88%EC%8B%9C-%ED%95%98%EA%B8%B0/
    const {
        config,
        response: {status},
    } = error;
    const originalRequest = config;
    if (status === 403) {
        if (!isTokenRefreshing) {
            // isTokenRefreshing이 false인 경우에만 token refresh 요청
            isTokenRefreshing = true;
            const {data} = await http.post(
                `/api/master/refresh`, // token refresh api
            );
            // 새로운 토큰 저장
            const {
                accessToken
            } = data;
            isTokenRefreshing = false;
            http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            store.commit({type: 'setSessionToken', token: accessToken})
            // 새로운 토큰으로 지연되었던 요청 진행
            onTokenRefreshed(accessToken);
        }
        // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
        const retryOriginalRequest = new Promise((resolve) => {
            addRefreshSubscriber((accessToken) => {
                originalRequest.headers.Authorization = "Bearer " + accessToken;
                resolve(http(originalRequest));
            });
        });
        return retryOriginalRequest;
    }
    if(status === 401) {
        await router.push({name: 'Login'});
    }
    if(status === 406) {
        alert("해당 요청에 접근할 권한이 없습니다.");
        return new Promise(() => {});
    }
    return Promise.reject(error);
})
const app = createApp(App)

app.use(createStore)
app.use(router)
app.use(store)
app.component(VueScrollingTable.name, VueScrollingTable);
app.component('VueDatePicker', VueDatePicker);
app.config.globalProperties.$axios = http;
app.config.globalProperties.$store = store;
app.config.globalProperties.$filters = filter;
app.mount('#app')
app.use(moment)


