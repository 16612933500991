import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import fullLayout from '../views/common/fullLayout.vue'
import store from "@/stores/store"


const router = createRouter({
  history: createWebHistory(),
  routes: [
  {
    path: '/',
    name: 'Intro',
    redirect: { name: 'Login' },
    meta: {
      title: '모든수산 관리자'
    }
  },
  {
    path: '/',
    component: fullLayout,
    meta:{
      needAuth : true
    },
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/temp'),
        name: 'Dashboard',
      },
      {
        path: '/entry',
        component: () => import('@/views/supply/Entry'),
        name: 'entry'
      }, {
        path: '/company',
        component: () => import('@/views/supply/companyList'),
        name: 'company'
      },{
        path: '/product_list',
        component: () => import('@/views/supply/productList'),
        name: 'product_list'
      }, {
        path: '/estimate',
        component: () => import('@/views/supply/Estimate'),
        name: 'estimate'
      }, {
        path: '/placing_order',
        component: () => import('@/views/supply/placingOrder'),
        name: 'placing_order'
      }, {
        path: '/product',
        component: () => import('@/views/product/productList'),
        name: 'product'
      },{
        path: '/product_modify',
        component: () => import('@/views/product/productModify'),
        name: 'product modify'
      },{
        path: '/product_price',
        component: () => import('@/views/product/productPrice'),
        name: 'product price'
      },{
        path: '/order',
        component: () => import('@/views/order/orderList'),
        name: 'order'
      },{
        path: '/order_detail',
        component: () => import('@/views/order/orderListDetail'),
        name: 'order detail'
      },{
        path: '/return',
        component: () => import('@/views/supply/companyList'),
        name: 'return'
      },{
        path: '/send_msg',
        component: () => import('@/views/order/sms'),
        name: 'send_msg'
      },{
        path: '/member',
        component: () => import('@/views/member/member'),
        name: 'member'
      },{
        path: '/member_modify',
        component: () => import('@/views/member/memberModify'),
        name: 'member modify'
      },{
        path: '/group',
        component: () => import('@/views/member/group'),
        name: 'group'
      },{
        path: '/setting',
        component: () => import('@/views/setting/setting'),
        name: 'setting'
      },{
        path: '/notice',
        component: () => import('@/views/setting/notice'),
        name: 'notice'
      },{
        path: '/notice_detail',
        component: () => import('@/views/setting/noticeDetail'),
        name: 'notice detail'
      },{
        path: '/notice_reg',
        component: () => import('@/views/setting/noticeRegister'),
        name: 'notice register'
      },{
        path: '/faq',
        component: () => import('@/views/setting/faq'),
        name: 'faq'
      },{
        path: '/faq_detail',
        component: () => import('@/views/setting/faqDetail'),
        name: 'faq detail'
      },{
        path: '/faq_reg',
        component: () => import('@/views/setting/faqRegister'),
        name: 'faq register'
      },{
        path: '/hidden/termsReg',
        component: () => import('@/views/hidden/termsCreate'),
        name: 'terms register'
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/common/Login'),
  },
]
})
router.beforeEach((to, from, next)=>{
  if(to.meta.needAuth) {
    // if(store.) 로그인 상태인지 확인 
    //아니면 로그인 화면으로 튕기기 
    if (!store.getters.getSessionToken) {
      alert("로그인이 필요합니다.\n로그인페이지로 이동합니다.")

      router.replace({name: 'Login'});
    }
  }
  next()
  document.body.scrollTo(0,0)
})

export default router;
