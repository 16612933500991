export default {
    commaNumber(value) {
        if (!value) return '';
        return String(value).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") || '';
    },
    phoneNumber(value) {
        if (!value) return '';
        let phone = String(value).replace(/[^0-9]/g, "");
        if (!/(^01[016789])-?([0-9]{3,4})-?([0-9]{4})$/.test(value)) {
            return value;
        }
        phone = String(phone).replace(/(01[016789])-?([0-9]{3,4})-?([0-9]{4})/, "$1-$2-$3") || '';
        return phone;
    },
    bizNumber(value) {
        if (!value) return '';
        return String(value).replace(/([0-9]{3})-?([0-9]{2})-?([0-9]{5})/, "$1-$2-$3") || '';
    }
}