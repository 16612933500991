// store/index.js
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        sessionToken: null,
        currentPath : null,
        role : null,
    },
    getters: {
        getSessionToken: (state) =>{
            return state.sessionToken
        },
        getCurrentPath: (state)  => {
            return state.currentPath
        },
        getRole: (state)  => {
            return state.role
        },
    },
    mutations: {
        setSessionToken(state,payload) {
            state.sessionToken = payload.token
        },
        setCurrentPath(state, payload) {
            state.currentPath = payload.path
        },
        setRole(state, payload) {
            state.role = payload.role
        }
    },
    actions: {
        setSessionToken(context,{token}) {
            context.commit('sessionToken',token);
        },
    },
    plugins: [//새로고침 데이터 유지용 추가
        createPersistedState()
    ],
});